// VERSION: 1.0.0
// CREATED: 28/11/2024
// MODIFIED: 30/01/2025


import "./act-styles.scss";
import { addGlobalEventListeners, mountTheSplide, ready } from "./app/global";
import { modals } from "./app/modals";
import { initForm } from "./app/forms";

ready(function () {
    console.log('Running OK');

    // HEADER
    window.addEventListener('scroll', () => {
        const header = document.querySelector('.actexwg-header');
        (window.scrollY > 0) ? header.classList.add('scrolled') : header.classList.remove('scrolled');
    });

    // SMOOTH SCROLLING
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener("click", function (e) {
            e.preventDefault();
            smoothScroll(this.getAttribute("href"), 800);
        });
    });

    // FORM
    if (document.querySelector('.actexwg-forms') !== null) { 
        initForm(document.querySelector('.actexwg-forms').getAttribute('data-checker'));
    }

    document.querySelector('.actexwg-header__bar').addEventListener('click', function () {
        const links = document.querySelector('.actexwg-header__links'),
            bar = document.querySelector('.actexwg-header__bar');

        if (links.classList.contains('disabled')) {
            links.classList.remove('disabled');
            bar.classList.remove('fa-bars');
            bar.classList.add('fa-xmark');
        } else {
            links.classList.add('disabled');
            bar.classList.remove('fa-xmark');
            bar.classList.add('fa-bars');
        }
    });

    if (document.querySelector('.actexwg-brandslide') !== null) {
        mountTheSplide('.splide.actexwg-brandslider', 'brands-slider');
    }

    if (document.querySelector('.actexwg-brands-loop-slider') !== null) {
        mountTheSplide('.splide.actexwg-brands-loopslider', 'brand-logos');
    }
});

// SMOOTH SCROLLING FUNCTION
function smoothScroll(target, duration) {
    const startPosition = window.scrollY,
        targetElement = document.querySelector(target);

    let headerOffset = (target == '#enquire-now') ? 100 : 10;

    if (!targetElement) return;

    const targetPosition = targetElement.offsetTop - headerOffset,
        distance = targetPosition - startPosition;
    
    let startTime = null;

    function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);

        window.scrollTo(0, run);

        if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    function easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
}