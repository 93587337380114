// VERSION: 1.0.0
// CREATED: 31/01/2025
// MODIFIED: 

import { addGlobalEventListeners } from "./global";

const phoneRegex = /^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/;
const postcodeRegex = /^\d{4}$/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validPhone = value => phoneRegex.test(value);
const validEmail = value => emailRegex.test(value);
const validPostcode = value => postcodeRegex.test(value);
const isRequired = value => value.trim().length == 0 ? false : true;

const standardFormFields = new Array('actexname', 'actexemail', 'actexphone', 'actexcompany', 'actexpostcode', 'actexmessage');
var formCheckList = 'standard';

const enableButton = (checklist) => {
    const button = document.querySelector('.actexwg-button.send-enquiry'),
        error = document.querySelector('.actexwg-forms__error');
    
    let checkTheseFields = '',
        errorFlag = 0;

    switch (checklist) {
        case 'standard':
            checkTheseFields = standardFormFields;
            break;
        default:
            break;
    }

    checkTheseFields.forEach(fieldname => {
        (!isRequired(document.querySelector('[name="' + fieldname + '"]').value)) && errorFlag++;
        (fieldname == 'actexphone') && (!validPhone(document.querySelector('[name="' + fieldname + '"]').value)) && errorFlag++;
        (fieldname == 'actexemail') && (!validEmail(document.querySelector('[name="' + fieldname + '"]').value)) && errorFlag++;
        (fieldname == 'actexpostcode') && (!validPostcode(document.querySelector('[name="' + fieldname + '"]').value)) && errorFlag++;
    });

    (errorFlag == 0) ? button.disabled = false : button.disabled = true;
}

const formStateHandle = (e) => {
    const error = document.querySelector('.actexwg-forms__error');

    if (!isRequired(e.target.value)) {
        e.target.classList.add('error');
        error.classList.remove('hide');
    } else {
        e.target.classList.remove('error');
        error.classList.add('hide');

        let errorFlag = true;
        switch (e.target.name) {
            case 'actexemail':
                errorFlag = validEmail(e.target.value);
                break;
            case 'actexphone':
                errorFlag = validPhone(e.target.value);
                break;
            case 'actexpostcode':
                errorFlag = validPostcode(e.target.value);
                break;
            default:
                break;
        }

        if (errorFlag == false) {
            e.target.classList.add('error');
            error.classList.remove('hide');
        }
    }

    enableButton(formCheckList);
}

const standardForm = () => {
    document.querySelector('[name="actexphone"]').setAttribute('maxlength', 10);
    document.querySelector('[name="actexpostcode"]').setAttribute('maxlength', 4);

    standardFormFields.forEach(fieldname => {
        document.querySelector('[name="' + fieldname + '"]').addEventListener('input', formStateHandle);
    });
}

export const initForm = (checklist) => {
    switch (checklist) {
        case 'standard':
            standardForm();
            break;
        default:
            break;
    }
}