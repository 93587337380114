// VERSION: 1.0.0
// CREATED: 28/11/2024
// MODIFIED: 30/01/2025

import Splide from "./splide.min.js";

// RELOAD
export function ready(callBackFunc) {
    if (document.readyState !== 'loading') {
        callBackFunc();
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', callBackFunc);
    } else {
        document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
                callBackFunc();
            }
        });
    }
}

// EVENT LISTENERS
export const addGlobalEventListeners = (type, selectors, callback) => {
    for (let s = 0; s < selectors.length; s++) {
        document.addEventListener(type, e => {
            if (e.target.matches(selectors[s])) callback(e)
        });
    }
}

// MOUNT THE SPLIDE
export const mountTheSplide = (splideInit, type) => {
    switch (type) {
        case 'brands-slider':
            var args = {
                type: 'loop',
                autoplay: true,
                gap: 15,
                autoHeight: true,
                focus: 'center',
                trimSpace: false,
                start: 0,
                rewind: true,
                padding: 15
            }
            break;
        case 'brand-logos':
            var args = {
                type: 'loop',
                padding: '80px',
                autoplay: true,
                gap: 80,
                fixedWidth: '120px',
                focus: 'center',
                trimSpace: false,
                pagination: false,
                arrows: false,
                speed: 1000,
                interval: 2000,
                drag: false,
                pausOnFocus: false
            }
            break;
        default:
            break;
    }

    const splideContainer = new Splide(splideInit, args);
    splideContainer.mount();
}